import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons"

const Ad_cat = ({ location }) => (
  <Layout>
    <SEO 
      pagetitle="交通広告新着"
      pagedesc="交通広告新着記事一覧"
      pagepath={location.pathname}
    />
    <section className="Advertising">
    <div className="container">
      <h2 className="bar">広告カテゴリー</h2>
      <div className="area">
      <p className="cat-post">交通広告の各広告媒体のカテゴリー一覧いただけます。<br />
      各カテゴリーから駅や路線の交通広告の記事をご覧になってください。<br />
      交通広告のご相談、その他、詳しい詳細につきましてはお問い合わせください。</p><br />
      </div>
      <div className="block">
        <div className="ad-detail">
          <h3 className="cat"><FontAwesomeIcon icon={faCheckDouble} /><Link to={`/cat/signage_cat/`}>サイネージ広告一覧</Link></h3>
          <p>Signage</p>
        </div>
        <div className="ad-detail">
          <h3 className="cat"><FontAwesomeIcon icon={faCheckDouble} /><Link to={`/cat/stationAd_cat/`}>駅広告一覧</Link></h3>
          <p>Station Media</p>
        </div>
        <div className="ad-detail">
          <h3 className="cat"><FontAwesomeIcon icon={faCheckDouble} /><Link to={`/cat/event_cat/`}>イベント一覧</Link></h3>
          <p>Event</p>
        </div>
      </div>
      <div className="block-1">
        <div className="ad-detail">
          <h3 className="cat" ><FontAwesomeIcon icon={faCheckDouble} /><Link to={`/cat/poster_cat/`}>ポスター広告一覧</Link></h3>
          <p>Poster</p>
        </div>
        <div className="ad-detail">
          <h3 className="cat"><FontAwesomeIcon icon={faCheckDouble} /><Link to={`/cat/trainAd_cat/`}>車内広告一覧</Link></h3>
          <p>Train Media</p>
        </div>
        <div className="ad-detail">
          <h3 className="cat"><FontAwesomeIcon icon={faCheckDouble} /><Link to={`/cat/web_cat/`}>WEB広告一覧</Link></h3>
          <p>Web Advertising</p>
        </div>
      </div>
    </div>
  </section>
  </Layout>
)


export default Ad_cat